import React from "react"
import "./scss/index.scss"

const Form = ({title, subtitle, children}) =>(
  <div className="form">
    <div className="form-headers row">
      <h3 className="form-headers-big col-lg-8">{title}</h3>
      <h4 className="form-headers-small col-lg-8">{subtitle}</h4>
    </div>
    <div className="row">
      {children}
    </div>
  </div>
)
export default Form