import React from "react";

import Layout from "../components/layout";
import Section from "../components/section/Section";
import Form from "../components/contactForm/Form";

const SprawdzTermin = ({data: {formData: {edges: formData}}}) => (
    <Layout post>
        <Section header={formData[0].node.title} hideHeader className="pt-lg-18">
            <Form title={formData[0].node.title} subtitle={<span className="fs-1 fw-light">{formData[0].node.acf.subtitle}</span>}>
                <div className="col-12">
                    <form className="form-ipt align-items-center">
                        <input className="form-ipt-small" type="text" required placeholder="Imię"/>
                        <input className="form-ipt-big" type="email" required placeholder="Twój adres e-mail"/>
                        <div className="form-spacer-newsletter">
                            <input type="checkbox" id="rodo" name="check" required value="agreed"/>
                            <label className="form-spacer-label-newsletter" htmlFor="rodo"> Wyrażam zgodę na przesyłanie mi przez Administratora informacji marketingowych za pośrednictwem środków komunikacji elektronicznej, w szczególności poprzez automatyczne systemy wywoławcze oraz na swoje urządzenia końcowe wszelkimi kanałami komunikacji. (np. telefon, e-mail, sms, mms).</label>
                        </div>
                        <input className="form-ipt-submit-newsletter btn btn-primary" value="Zapisz się" type="submit"/>
                    </form>
                </div>
            </Form>
        </Section>
    </Layout>
)

export const formQuery = graphql`
    query {
      formData: allWordpressPage(filter: {wordpress_id: {eq: 221}}) {
        edges {
         node {
            title
            acf {
              subtitle
            }
         }
        }
      }
    }
`;

export default SprawdzTermin
